import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import { Helmet } from "react-helmet"
import {
  Section,
  SectionHeading,
  SectionLedeDiv,
} from "../styledComponents/section"
import LayoutNoStripe from "../components/layoutNoStripe"

const Heading = ({ level = 1, location, children }) => (
  <SectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </SectionHeading>
)

const Thankyou = ({ data, location }) => {

  return (
    <>
    <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />x
    </Helmet>
    <LayoutNoStripe displayNavBorder location={location}>
      <Section>
        <Heading level={1}>
          {data.wpPage.title}
        </Heading>
        <SectionLedeDiv>
          {parse(data.wpPage.content)}
        </SectionLedeDiv>       
      </Section>
    </LayoutNoStripe>
    </>
  )
}

export default Thankyou

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "thank-you" }) {
      title
      content
    }
  }
`
